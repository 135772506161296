import { Component, Input, SimpleChanges } from '@angular/core';
import { BaseEntityDetails } from 'src/app/models/classes/entity-details.class';
import { PortfolioSeries } from 'src/app/models/classes/portfolio-series.class';
import { AssetClassPipe } from 'src/app/pipes/asset-class.pipe';
import { HighchartsService } from 'src/app/services/highcharts-service.service';
import { TvpiIrrFormatService } from 'src/app/services/tvpi-irr-format.service';

@Component({
  selector: 'app-series-dashboard-summary',
  templateUrl: './series-dashboard-summary.component.html',
  styleUrls: ['./series-dashboard-summary.component.scss']
})
export class SeriesDashboardSummaryComponent {
  @Input() portfolioSummary: BaseEntityDetails[];
  @Input() seriesSummary: PortfolioSeries[];
  @Input() iconSet: Record<string, string> = {};
  @Input() selectedDateObj: Date;
  assetClassImage: Array<string> = [];
  noResultsPortfolio: boolean = true;
  noResultsSeries: boolean = true;

  constructor(public highchartService: HighchartsService, private assetClassPipe: AssetClassPipe, private tvpiIrrFormat: TvpiIrrFormatService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.portfolioSummary && this.portfolioSummary.length > 0) {
      this.getPortfolioIrrAndTvpiValues();
      this.noResultsPortfolio = false;
    }
    if (changes?.seriesSummary && this.seriesSummary.length > 0) {
      this.getSeriesIrrAndTvpiValues();
      this.noResultsSeries = false;
    }
  }

  getPortfolioIrrAndTvpiValues() {
    this.tvpiIrrFormat.deriveIrrAndTvpiIconAndTitle(this.portfolioSummary[0]?.qoQIrrChange, this.portfolioSummary[0]?.irr, this.portfolioSummary[0]?.priorIrr, this.iconSet, this.portfolioSummary[0]);
    this.tvpiIrrFormat.deriveIrrAndTvpiIconAndTitle(this.portfolioSummary[0]?.qoQTvpiChange, this.portfolioSummary[0]?.tvpi, this.portfolioSummary[0]?.priorTvpi, this.iconSet, this.portfolioSummary[0], true);
  }

  getSeriesIrrAndTvpiValues() {
    this.seriesSummary[0].predominantAssetClasses.forEach(ele => {
      if (ele === 'ARS') {
        ele = 'Absolute Returns Strategy'
      }
      if (ele) {
        this.assetClassImage = [ele, this.iconSet[this.assetClassPipe.transform(ele)]];
      }
    });
    this.tvpiIrrFormat.deriveIrrAndTvpiIconAndTitle(this.seriesSummary[0]?.qoQIrrChange, this.seriesSummary[0]?.irr, this.seriesSummary[0]?.priorIrr, this.iconSet, this.seriesSummary[0]);
    this.tvpiIrrFormat.deriveIrrAndTvpiIconAndTitle(this.seriesSummary[0]?.qoQTvpiChange, this.seriesSummary[0]?.tvpi, this.seriesSummary[0]?.priorTvpi, this.iconSet, this.seriesSummary[0], true);
  }
}
