import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Routes } from './routes.class';
import { ApiResponse } from 'src/app/models/interfaces/api-response.interface';
import { map } from 'rxjs/operators';
import { PortfolioSeriesDashboard } from 'src/app/models/classes/portfolio-series-dashboard.class';
import { HoldingDashboard } from 'src/app/models/classes/holding-dashboard.class';
import { AssetDashboard } from 'src/app/models/classes/asset-dashboard.class';
import { UserService } from 'src/app/services/user.service';
import { PortfolioSeriesMeta } from 'src/app/models/classes/portfolio-series-meta.class';
import { HoldingMeta } from 'src/app/models/classes/holding-meta.class';
import { InvestorDashboard } from 'src/app/models/classes/investor-dashboard.class';
import { AssetMeta } from 'src/app/models/classes/asset-meta.class';
import { SupportRequest } from 'src/app/models/classes/support-request.class';
import { InvestorMeta } from 'src/app/models/classes/investor-meta.class';

const hostname = location.origin.includes('localhost:4200') ? 'https://clientscope-dev.gcmgrosvenor.com/' : '';

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(
    private httpClient: HttpClient,
    private userService: UserService
  ) { }

  getInvestors(): Observable<InvestorMeta[]> {
    var route = `${hostname + Routes.metadata}/investors`;
    return this.httpClient
      .get<ApiResponse<InvestorMeta[]>>(route)
      .pipe(map(response => {
        return response.data
      }));
  }

  getInvestorPortfolioDashboardDetails(investorId: number, reportingPeriod: string): Observable<InvestorDashboard> {
    var route = `${hostname + Routes.dashboardInvestor}/${investorId}`;
    if (reportingPeriod) route += `?reportingPeriod=${reportingPeriod}`;
    return this.httpClient
      .get<ApiResponse<InvestorDashboard>>(route)
      .pipe(map(response => {
        return response.data;
      }));
  }

  getPortfolioSeriesDashboardDetails(seriesId: number, reportingPeriod: string): Observable<PortfolioSeriesDashboard> {
    var route = `${hostname + Routes.dashboardPortfolioSeries}/${seriesId}`;
    if (reportingPeriod) route += `?reportingPeriod=${reportingPeriod}`;
    return this.httpClient
      .get<ApiResponse<PortfolioSeriesDashboard>>(route)
      .pipe(map(response => {
        return response.data;
      }));
  }

  getHoldingDashboardDetails(seriesId: number, holdingId: number, reportingPeriod: string): Observable<HoldingDashboard> {
    var route = `${hostname + Routes.dashboardPortfolioSeries}/${seriesId}/holdings/${holdingId}`;
    if (reportingPeriod) route += `?reportingPeriod=${reportingPeriod}`;
    return this.httpClient
      .get<ApiResponse<HoldingDashboard>>(route)
      .pipe(map(response => {
        return response.data;
      }));
  }

  getAssetDashboardDetails(seriesId: number, holdingId: number, assetId: number, reportingPeriod: string): Observable<AssetDashboard> {
    var route = `${hostname + Routes.dashboardPortfolioSeries}/${seriesId}/holdings/${holdingId}/assets/${assetId}?reportingPeriod=${reportingPeriod}`;
    return this.httpClient
      .get<ApiResponse<AssetDashboard>>(route)
      .pipe(map(response => {
        return response.data;
      }));
  }

  getSnapshotRoute(seriesId: number, reportingPeriod: string, holdingId?: number, assetMasterId?: number): string {
    let route = "";
    if (holdingId && assetMasterId) {
      route = `${hostname + Routes.dashboardPortfolioSeries}/${seriesId}/holdings/${holdingId}/assets/${assetMasterId}/report?reportingPeriod=${reportingPeriod}`;
    }
    else if (holdingId) {
      route = `${hostname + Routes.dashboardPortfolioSeries}/${seriesId}/holdings/${holdingId}/report?reportingPeriod=${reportingPeriod}`;
    }
    else {
      route = `${hostname + Routes.dashboardPortfolioSeries}/${seriesId}/report?reportingPeriod=${reportingPeriod}`;
    }
    return route;
  }

  downloadSnapshot(url: string): Observable<HttpResponse<Blob>> {
    const requestOptions: Object = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.userService.getOktaIdToken()}`
      }),
      observe: 'response',
      responseType: 'blob'
    }
    return this.httpClient
      .get<any>(url, requestOptions)
      .pipe(map((response: HttpResponse<Blob>) => {
        return response;
      }))
  }

  getPortfolioSeriesDashboardMetadata(seriesId: number, investorId: number): Observable<PortfolioSeriesMeta> {
    var route = `${hostname + Routes.metadata}/investors/${investorId}/portfolioSeries/${seriesId}`;
    return this.httpClient
      .get<ApiResponse<PortfolioSeriesMeta>>(route)
      .pipe(map(response => {
        return response.data;
      }));
  }

  getHoldingDashboardMetadata(seriesId: number, holdingId: number): Observable<HoldingMeta> {
    var route = `${hostname + Routes.metadata}/portfolioSeries/${seriesId}/holdings/${holdingId}`;
    return this.httpClient
      .get<ApiResponse<HoldingMeta>>(route)
      .pipe(map(response => {
        return response.data;
      }));
  }

  getAssetDashboardMetadata(seriesId: number, holdingId: number, assetMasterId: number): Observable<AssetMeta> {
    var route = `${hostname + Routes.metadata}/portfolioSeries/${seriesId}/holdings/${holdingId}/assets/${assetMasterId}`;
    return this.httpClient
      .get<ApiResponse<AssetMeta>>(route)
      .pipe(map(response => {
        return response.data;
      }));
  }

  contactSupport(supportRequest: SupportRequest): Observable<any> {
    var route = `${hostname + Routes.contactSupport}`;

    return this.httpClient
      .post<ApiResponse<any>>(route, supportRequest)
      .pipe(map(response => {
        return response;
      })
      );
  }

  //Update tutorialComplete claim in okta from False to True when tutorial is completed
  updateTutorialComplete(): Observable<any> {
    let route = `${hostname + Routes.tutorialCompleted}`;
    const httpOptions: Object = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.userService.getOktaIdToken()}`
      })
    }
    return this.httpClient.post(route, httpOptions).pipe(map(response => {return response;}));
  }

  getPortfoliosMetadata(): Observable<[]> {
    var route = `${hostname + Routes.metadata}/portfolios`;
    return this.httpClient
      .get<ApiResponse<[]>>(route)
      .pipe(map(response => {
        return response.data
      }));
  }

}