import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-no-access-error',
  templateUrl: './no-access-error.component.html',
  styleUrls: ['./no-access-error.component.scss']
})
export class NoAccessErrorComponent implements OnInit {
  assignedInvestorId: number[];
  @Output() updateInvestor: EventEmitter<number> = new EventEmitter();

  constructor(
    private router: Router, 
    private userService : UserService,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.assignedInvestorId = this.userService.getInvestorIds();
    if ((!this.assignedInvestorId || this.assignedInvestorId.length == 0) && this.activeRoute.snapshot.params['investorId']) {
      this.assignedInvestorId.push(this.activeRoute.snapshot.params['investorId'])
    }
  }

  redirectUser(ids : number[]) {
    if (ids && ids.length > 0) {
      this.router.navigate(['/dashboard'], {
        queryParams: { investorid: ids[0] },
      });
      this.updateInvestor.emit(ids[0]);
    }
  }
}
