<div class="row mt-3" data-cy="noAccessError">
    <div class="card">
        <div class="card-body">
            <div class="no-access-img"></div>
            <span class="card-text mb-2">
                You have not been granted access to this data. <br/>
                If you believe this is an error, please contact <br/>
                our <a class="reroute-link" routerLink="/contact">Client Support team</a>. <br/>
                Otherwise, return to the <a class="reroute-link" href="javaScript:;" (click)="redirectUser(assignedInvestorId)">Investor Dashboard</a>.
            </span>
        </div>
    </div>
</div>