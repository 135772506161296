import { Component, Input, SimpleChanges, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExportCsvService } from 'src/app/services/export-csv.service';
import { CsvModes } from 'src/app/core/csv-modes.class';
import { BreadcrumbDataService } from 'src/app/services/breadcrumb-data.service';
import { DateFormatPipe } from 'src/app/pipes/date-format.pipe';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { DealHierarchy } from 'src/app/models/classes/deal-hierarchy.class';
import { TableLogicService } from 'src/app/services/table-logic.service';

@Component({
  selector: 'app-series-holdings-table',
  templateUrl: './series-holdings-table.component.html',
  styleUrls: ['./series-holdings-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class SeriesHoldingsTableComponent {
  @Input() iconSet;
  @Input() investmentTypes;
  @Input() summary;
  @Input() portfolioName: string = '';
  @Input() seriesName: string = '';
  @Input() asOfDate: string = '';
  @Input() currency: string;
  expandView = false;
  displayedColumns: string[] = ['name', 'predominantStrategy', 'commitment', 'contributions', 'distributions', 'nav', 'totalValue', 'tvpi', 'irr'];
  expandedDisplayedColumns: string[] = ['name', 'predominantRealizationType', 'predominantStrategy', 'vintageYear', 'valuationDate', 'commitment', 'contributions', 'remainingCommitment', 'distributions', 'reportedValue', 'subsequentContributions', 'subsequentDistributions', 'fxGainLoss', 'nav', 'totalValue', 'tvpi', 'irr'];
  columnsToDisplay: string[] = this.displayedColumns;
  @ViewChildren(MatSort) sorts = new QueryList<MatSort>;
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<DealHierarchy>>;
  dataSources: MatTableDataSource<DealHierarchy>[] = []
  sortingInitialized: boolean = false;
  expandedDeal: MatTableDataSource<DealHierarchy>[] = [];

  constructor(private tableLogicService: TableLogicService, private breadcrumbDataService: BreadcrumbDataService, private exportCsvService: ExportCsvService, public activatedRoute: ActivatedRoute, private dateFormatPipe: DateFormatPipe) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.investmentTypes && this.investmentTypes) {
      this.formatTableValues();
      this.sortingInitialized = false;
      this.sorts = new QueryList<MatSort>;
    }
  }

  ngDoCheck() {
    this.initializeSorting();
  }

  initializeSorting() {
    if (!this.sortingInitialized) {
      if (this.sorts.length > 0) {
        this.dataSources.forEach((x, index) => {
          x.sort = this.sorts.find((_, i) => i == index)
        });
        this.sortingInitialized = true;
      }
    }
  }
  
  formatTableValues() {
    this.investmentTypes = this.tableLogicService.sortDataForSeriesTable(this.investmentTypes);
    this.investmentTypes.forEach((investmentType, i) => {
      investmentType['expanded'] = true;
      this.dataSources[investmentType['priority'] - 1] = new MatTableDataSource(investmentType.deals);
      investmentType.deals.forEach(deal => {
        deal['showHoldings'] = this.tableLogicService.checkDealToHoldingMapping(deal.holdings);
        if (deal.showHoldings) {
          this.expandedDeal.push(deal)
          deal['expanded'] = true;
        }
        deal.holdings.forEach(holding => {
          if (deal.showHoldings) {
            holding['disableDrillThrough'] = this.tableLogicService.checkHoldingDrillThrough(holding);
          } else {
            deal['disableDrillThrough'] = this.tableLogicService.checkHoldingDrillThrough(holding);
          }
        })
      })
    })
  }

  redirectToHoldings(holding) {
    const params = this.activatedRoute.snapshot.params;
    return this.breadcrumbDataService.redirectToHoldingsPage(holding, params);
  }

  getQueryParams() {
    return this.activatedRoute.snapshot.queryParams
  }

  exportSeriesHoldingsDataToCsv(event: any) {
    event.target.disabled = true;
    let seriesHoldingArray = [], invTypeArray = [];
    const filename = this.exportCsvService.getExportFilename(CsvModes.seriesHolding, this.asOfDate, this.portfolioName, this.seriesName);
    const headerForSeriesHolding = this.exportCsvService.getCsvHeader(CsvModes.seriesHolding);
    const headerForInvestmentType = this.exportCsvService.getCsvHeader(CsvModes.InvestmentType);
    seriesHoldingArray.push(headerForSeriesHolding);
    invTypeArray.push(headerForInvestmentType);
    this.investmentTypes.forEach(invType => {
      let invTypeData = {};
      invTypeData['invType'] = invType.name;
      invTypeData['invTypeCommitment'] = invType.commitment;
      invTypeData['invTypeReportedContributions'] = invType.reportedContributions;
      invTypeData['invTypeContributions'] = invType.contributions;
      invTypeData['invTypeRemainingCommitment'] = invType.remainingCommitment;
      invTypeData['invTypeReportedDistributions'] = invType.reportedDistributions;
      invTypeData['invTypeDistributions'] = invType.distributions;
      invTypeData['invTypeReportedValue'] = invType.reportedValue;
      invTypeData['invTypeSubsequentContributions'] = invType.subsequentContributions;
      invTypeData['invTypeSubsequentDistributions'] = invType.subsequentDistributions;
      invTypeData['invTypeFxGainLoss'] = invType.fxGainLoss;
      invTypeData['invTypeNav'] = invType.nav;
      invTypeData['invTypeTotalValue'] = invType.totalValue;
      invTypeData['invTypeTvpi'] = invType.tvpi;
      invTypeData['invTypeIrr'] = invType.irr;
      invTypeArray.push(invTypeData);

      const sortedDeals = this.exportCsvService.sortArrayAscending(invType.deals);
      sortedDeals.forEach(deal => {
        const sortedHoldings = this.exportCsvService.sortArrayAscending(deal.holdings);
        sortedHoldings.forEach(holding => {
          let data = {};
          data['invType'] = invType.name;
          data['dealName'] = deal.name;
          data['holdingName'] = holding.name;
          data['invStatus'] = deal.predominantRealizationType;
          data['strategy'] = deal.predominantStrategy;
          data['vintageYear'] = deal.vintageYear;
          data['valuationDateFormatted'] = this.dateFormatPipe.transform(holding.valuationDate, 'M/d/yy');
          data['holdingCommitment'] = holding.commitment;
          data['holdingReportedContributions'] = holding.reportedContributions;
          data['holdingContributions'] = holding.contributions;
          data['holdingRemainingCommitment'] = holding.remainingCommitment;
          data['holdingReportedDistributions'] = holding.reportedDistributions;
          data['holdingDistributions'] = holding.distributions;
          data['holdingReportedValue'] = holding.reportedValue;
          data['holdingSubsequentContributions'] = holding.subsequentContributions;
          data['holdingSubsequentDistributions'] = holding.subsequentDistributions;
          data['holdingFxGainLoss'] = holding.fxGainLoss;
          data['holdingNav'] = holding.nav;
          data['holdingTotalValue'] = holding.totalValue;
          data['holdingTVPI'] = holding.tvpi;
          data['holdingIRR'] = holding.irr;
          seriesHoldingArray.push(data);
        })
      })
    })
    const worksheets = [{ name: 'Series Holdings Table', csvArray: seriesHoldingArray }, { name: 'Investment Type Subtotals', csvArray: invTypeArray }]
    this.exportCsvService.exportToExcelFile(filename, worksheets);
    event.target.disabled = false;
  }

  updateColumns(expandView) {
    this.expandView = expandView
    if (expandView) {
      this.columnsToDisplay = this.expandedDisplayedColumns;
    } else {
      this.columnsToDisplay = this.displayedColumns;
    }
  }

  scrollTableHandler(event, investmentType) {
    if (event.target.scrollLeft > 0) {
      investmentType['displayShadowOnTable'] = true;
    } else {
      investmentType['displayShadowOnTable'] = false;
    }
  }

  expandTable(investmentType) {
    investmentType.expanded = !investmentType.expanded;
    if (investmentType.expanded) {
      this.sortingInitialized = false;
      this.sorts = new QueryList<MatSort>;
      this.initializeSorting();
    }
  }
}
