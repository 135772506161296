import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { BreadcrumbDataService } from 'src/app/services/breadcrumb-data.service';
import { ProxyService } from 'src/app/services/proxy.service';
import { UserService } from 'src/app/services/user.service';
import { ContentfulService } from 'src/app/services/contentful.service';
import { EnvironmentVariablesService } from 'src/app/services/environment-variables.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  @Input() showMobileSideNav: boolean = false;
  currentPage;
  activelink: string = 'dashboard';
  error: any;
  navigationItems: any[] = [];
  externalRedirectImage: string = '';
  displayName: string = '';
  @Output() openSideNav: EventEmitter<any> = new EventEmitter();
  loading = true;

  constructor(
    public router: Router,
    public proxyService: ProxyService,
    private userService: UserService,
    private breadcrumbDataService: BreadcrumbDataService,
    private ContentfulService: ContentfulService, 
    public evService: EnvironmentVariablesService
  ) { } // note you have to use Public because you are using it in html file too.

  ngOnInit(): void {
    this.getContentFulData();
    this.currentPage = this.router.url;
    this.displayName = this.userService.getUserFullName();
  }

  getContentFulData(): void {
    this.ContentfulService.fetchCommonAssets('sidebar', '').subscribe((result: any) => {
      this.loading = result.loading;
      this.error = result.error;
      this.navigationItems = [];
      if (result?.data && result?.data?.navigation.linkElementsCollection.items) {
        let navItems = result?.data?.navigation.linkElementsCollection.items;
        // check nav items against what user has access to to show icons in side nav
        navItems.forEach(navItem => {
          if (!this.navigationItems.includes(navItem)) {
            navItem['displayText'] = navItem['displayText'].trim().split(/\s+/);
            if (navItem.name === "Dashboard" || navItem.name === "Contact") {
              this.navigationItems.push(navItem)
            }
            if (this.userService.getApplicationAccess().indexOf('ClientDocument') > -1) {
              // check if user has access to client docs, if yes then show client docs as docs in nav
              if (navItem['name'] === 'Documents - ClientDocs') {
                navItem['url'] = this.evService.clientDocs;
                this.navigationItems.push(navItem);
              }
            } else if (this.userService.getApplicationAccess().indexOf('VISTAONE') > -1) {
              // check if user has access to vista one, if yes then show vista one as docs in nav
              navItem['name'] === 'Documents - VistaOne' && this.navigationItems.push(navItem)
            }
            if (this.userService.getApplicationAccess().indexOf('PpaReportPortfolio') > -1 && navItem['name'] === 'ARS') {
              this.navigationItems.push(navItem)
            }
            if (this.userService.doesUserHaveSEIAccess() && navItem['name'] === 'Documents - SEI') {
              this.navigationItems.push(navItem)
            }
          }
        })
      }
    })
  }

  redirectToPages(url) {
    this.openSideNav.emit();
    if (url === 'dashboard') {
      const id = this.userService.getCookieValue('selectedInvestorId');
      return this.breadcrumbDataService.sendHrefForDashboard(id)
    }
    else {
      var newUrl = new URL(location.origin + '/' + url);
      return newUrl;
    }
  }

  isLinkActive(url): boolean {
    const baseUrl = this.router.url
    if (url === 'dashboard' && ((baseUrl.indexOf('dashboard') > -1) || (baseUrl.indexOf('series') > -1) || (baseUrl.indexOf('holdings') > -1))) {
      return true;
    }
    else {
      const matchOptions: IsActiveMatchOptions = { paths: 'exact', matrixParams: 'ignored', fragment: 'ignored', queryParams: 'ignored' };
      return this.router.isActive(url, matchOptions) ? true : false;
    }
  }
}
