import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertPosition, SweetAlertResult } from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

  constructor() { }

  showToast(title: string, message: string, icon: SweetAlertIcon, timer: number = 5000, position: SweetAlertPosition = 'top-end', customClass?: string) {
    Swal.fire({
      icon,
      title: title,
      html: message,
      toast: true,
      position,
      timer,
      timerProgressBar: true,
      customClass
    })
  }

  modalAlert(title: string, message: string, icon: SweetAlertIcon = 'error') {
    Swal.fire({
      icon,
      title: title,
      html: message,
      allowOutsideClick: false,
      confirmButtonColor: '#0a4671'
    })
  }

  modalAlertWithCancelBtn(title: string, message: string, icon: SweetAlertIcon = 'error', cancelButtonText: string = 'Cancel', confirmButtonText: string = 'Okay'): Promise<SweetAlertResult<any>> {
    return Swal.fire({
      icon,
      title: title,
      html: message,
      allowOutsideClick: false,
      confirmButtonColor: '#0a4671',
      confirmButtonText,
      cancelButtonText,
      cancelButtonColor: '#d01e29',
      showCancelButton: true
    })
  }
}
