<ng-container *ngIf="investmentTypes && summary && iconSet">
    <div class="row title-row mt-4">
        <div class="col-6">
            <div class="table-title d-flex text-md lg:text-2xl">Investment Holdings
            </div>
            <div class="since-inception-text text-sm lg:text-md" data-cy="tableCurrency">Since Inception | Expressed in {{currency}} (in millions)</div>
        </div>
        <div class="col-6 flex flex-row flex-wrap pl-0 expanded-export">
            <app-expanded-view-slider (updateTableColumns)="updateColumns($event)"
                [expandView]="expandView"></app-expanded-view-slider>
            <div class="text-right" tourAnchor="series.dashboard.export">
                <button type="button" *ngIf="investmentTypes && investmentTypes?.length > 0;"
                    (click)="exportSeriesHoldingsDataToCsv($event)"
                    class="btn export-csv-btn btn-outline-primary text-xs lg:text-md md:before:top-2 lg:before:top-3 before:top-[30px]"
                    data-cy="exportCsvButton">Export to Excel</button>
            </div>
        </div>
    </div>
    <div class="flex flex-row title-values">
        <div class="flex-initial w-32">
            <div class="tvpi-value text-md lg:text-2xl" data-cy="summaryTvpiValue">{{summary.tvpi | tvpiValueFormat}}
            </div>
            <div class="tvpi-text text-sm lg:text-md">TVPI</div>
        </div>
        <div class="flex-initial w-32">
            <div class="irr-value text-md lg:text-2xl" data-cy="summaryIrrValue">{{summary.irr | irrValueFormat}}</div>
            <div class="irr-text text-sm lg:text-md">IRR </div>
        </div>
    </div>
    <div class="card series-holdings-card " data-cy="seriesHoldingCard"
        *ngIf="investmentTypes && investmentTypes?.length > 0; else noResults">
        <ng-container *ngFor="let investmentType of investmentTypes | sortBy:'asc' : 'priority'; let i = index">
            <div class="overflow-x-auto" attr.data-cy="scrollTable{{i}}"
                (scroll)="scrollTableHandler($event, investmentType)">
                <table [ngClass]="{'width-3300 lg:min-w-3300':expandView, 'pb-3': !expandView}"
                    class="series-table last:mb-8 w-full">
                    <thead data-cy="investmentTypeHeader" class="inv-type-border-bottom">
                        <img class="expand-collapse-icon mb-[-225px] md:mb-[-235px] lg:mb-[-205px]"
                            (click)="expandTable(investmentType)"
                            attr.data-cy="collapseIcon{{i}}"
                            src="{{ investmentType.expanded ? iconSet['icon.series.collapse'] : iconSet['icon.expand']}}">
                        <tr class="leading-4 md:leading-relaxed">
                            <th class="investment-type text-md lg:text-2xl pt-5 lg:pt-0 min-w-175  sticky-header"
                                [ngClass]="{'width-305 max-w-175 lg:min-w-305': expandView, 'width-23': !expandView, 'shadow-background':investmentType?.displayShadowOnTable, 'normal-background':!investmentType?.displayShadowOnTable}">
                                <div class="flex">
                                    {{investmentType.name}}
                                    <app-info-icon tooltipClass="info-icon-top-2" tooltipPosition="right"
                                        tooltipInfo="Totals may not sum due to rounding">
                                    </app-info-icon>
                                </div>
                            </th>
                            <th class="investment-type text-md lg:text-2xl pt-5 min-w-85"
                                [ngClass]="expandView ? 'width-580 max-w-365 lg:min-w-580' : 'width-12'">
                                <!-- leave room for space between name and reported values -->
                            </th>
                            <th class="text-right value text-md lg:text-xl pt-5 lg:pt-3 min-w-100"
                                [ngClass]="expandView ? 'width-160 lg:min-w-160' : 'width-10'"
                                data-cy="investmentTypeBalanceValue">
                                {{investmentType.commitment | currencyFormat:currency:'Millions':true:true}}
                            </th>
                            <th class="text-right value text-md lg:text-xl pt-5 lg:pt-3 min-w-100"
                                [ngClass]="expandView ? 'width-245 lg:min-w-245' : 'width-10'"
                                data-cy="investmentTypeBalanceValue">
                                {{(expandView ? investmentType.reportedContributions : investmentType.contributions) |
                                currencyFormat:currency:'Millions':true:true}}
                            </th>
                            <th class="width-245 text-right value text-md lg:text-xl pt-5 lg:pt-3 lg:min-w-245"
                                *ngIf="expandView" data-cy="investmentTypeBalanceValueExpanded">
                                {{investmentType.remainingCommitment | currencyFormat:currency:'Millions':true:true}}
                            </th>
                            <th class="text-right value text-md lg:text-xl pt-5 lg:pt-3 min-w-100"
                                [ngClass]="expandView ? 'width-245 lg:min-w-245' : 'width-10'"
                                data-cy="investmentTypeBalanceValue">
                                {{(expandView ? investmentType.reportedDistributions : investmentType.distributions) |
                                currencyFormat:currency:'Millions':true:true}}
                            </th>
                            <th class="width-160 text-right value text-md lg:text-xl pt-5 lg:pt-3 lg:min-w-160"
                                *ngIf="expandView" data-cy="investmentTypeBalanceValueExpanded">
                                {{investmentType.reportedValue | currencyFormat:currency:'Millions':true:true}}
                            </th>
                            <th class="width-245 text-right value text-md lg:text-xl pt-5 lg:pt-3 lg:min-w-245"
                                *ngIf="expandView" data-cy="investmentTypeBalanceValueExpanded">
                                {{investmentType.subsequentContributions |
                                currencyFormat:currency:'Millions':true:true}}
                            </th>
                            <th class="width-245 text-right value text-md lg:text-xl pt-5 lg:pt-3 lg:min-w-245"
                                *ngIf="expandView" data-cy="investmentTypeBalanceValueExpanded">
                                {{investmentType.subsequentDistributions |
                                currencyFormat:currency:'Millions':true:true}}
                            </th>
                            <th class="width-160 text-right value text-md lg:text-xl pt-5 lg:pt-3 lg:min-w-160"
                                *ngIf="expandView" data-cy="investmentTypeBalanceValueExpanded">
                                {{investmentType.fxGainLoss | currencyFormat:currency:'Millions':true:true}}
                            </th>
                            <th class="text-right value text-md lg:text-xl pt-5 lg:pt-3 min-w-70"
                                [ngClass]="expandView ? 'width-160 lg:min-w-160' : 'width-10'"
                                data-cy="investmentTypeBalanceValue">
                                {{investmentType.nav | currencyFormat:currency:'Millions':true:true}}
                            </th>
                            <th class="text-right value text-md lg:text-xl pt-5 lg:pt-3 min-w-70"
                                [ngClass]="expandView ? 'width-160 lg:min-w-160' : 'width-10'"
                                data-cy="investmentTypeBalanceValue">
                                {{investmentType.totalValue | currencyFormat:currency:'Millions':true:true}}
                            </th>
                            <th class="text-right value text-md lg:text-xl pt-5 lg:pt-3 min-w-70"
                                [ngClass]="expandView ? 'width-160 lg:min-w-160' : 'width-7'"
                                data-cy="investmentTypeTvpiValue">
                                {{investmentType.tvpi | tvpiValueFormat}}
                            </th>
                            <th class="text-right value pl-2 text-md lg:text-xl pt-5 lg:pt-3 min-w-70"
                                [ngClass]="expandView ? 'width-160 lg:min-w-160' : 'width-7'"
                                data-cy="investmentTypeIrrValue">
                                {{investmentType.irr | irrValueFormat}}
                            </th>
                        </tr>
                        <tr>
                            <th class="value-title text-right pl-0 min-w-175 sticky-header"
                                [ngClass]="{'width-305 max-w-175 lg:min-w-305': expandView, 'width-23': !expandView}"
                                [ngClass]="investmentType?.displayShadowOnTable ? 'shadow-background' : 'normal-background'">
                            </th>
                            <th class="value-title text-right pl-0 min-w-85"
                                [ngClass]="expandView ? 'width-580 max-w-365 lg:min-w-580' : 'width-12'">
                            </th>
                            <th class="text-right value-title text-sm lg:text-md min-w-100"
                                [ngClass]="expandView ? 'width-160 lg:min-w-160' : 'width-10'">
                                Commitment
                            </th>
                            <th class="text-right value-title text-sm lg:text-md min-w-100"
                                [ngClass]="expandView ? 'width-245 lg:min-w-245' : 'width-10'">
                                {{expandView ? 'Reported ' : ''}}Contributions
                            </th>
                            <th class="width-245 text-right value-title text-sm lg:text-md min-w-100 lg:min-w-245"
                                *ngIf="expandView" data-cy="expandedOnlyLabel">
                                Remaining Commitment
                            </th>
                            <th class="text-right value-title text-sm lg:text-md min-w-100"
                                [ngClass]="expandView ? 'width-245 lg:min-w-245' : 'width-10'">
                                {{expandView ? 'Reported ' : ''}}Distributions
                            </th>
                            <th class="width-160 text-right value-title text-sm lg:text-md min-w-70 lg:min-w-160"
                                *ngIf="expandView" data-cy="expandedOnlyLabel">
                                Reported Value
                            </th>
                            <th class="width-245 text-right value-title text-sm lg:text-md min-w-100 lg:min-w-245"
                                *ngIf="expandView" data-cy="expandedOnlyLabel">
                                Subsequent Contributions
                            </th>
                            <th class="width-245 text-right value-title text-sm lg:text-md min-w-100 lg:min-w-245"
                                *ngIf="expandView" data-cy="expandedOnlyLabel">
                                Subsequent Distributions
                            </th>
                            <th class="width-160 text-right value-title text-sm lg:text-md min-w-100 lg:min-w-160"
                                *ngIf="expandView" data-cy="expandedOnlyLabel">
                                FX Gain/(Loss)
                            </th>
                            <th class="text-right value-title text-sm lg:text-md min-w-70"
                                [ngClass]="expandView ? 'width-160 lg:min-w-160' : 'width-10'">
                                Net Asset Value
                            </th>
                            <th class="text-right value-title text-sm lg:text-md min-w-70"
                                [ngClass]="expandView ? 'width-160 lg:min-w-160' : 'width-10'">
                                Total Value
                            </th>
                            <th class="text-right value-title text-sm lg:text-md min-w-70"
                                [ngClass]="expandView ? 'width-160 lg:min-w-160' : 'width-7'">
                                TVPI
                            </th>
                            <th class="text-right value-title text-sm lg:text-md min-w-70"
                                [ngClass]="expandView ? 'width-160 lg:min-w-160' : 'width-7'">
                                IRR
                            </th>
                        </tr>

                    </thead>
                </table>
                <div *ngIf="investmentType.expanded" attr.data-cy="investmentTypeTable{{i}}"
                    class="investment-type-table">
                    <table mat-table matSort [dataSource]="dataSources[investmentType.priority - 1]"
                        class="mb-3 investment-type-section" multiTemplateDataRows
                        [ngClass]="{'width-3300 lg:min-w-3300':expandView}">
                        <!-- Name Column -->
                        <ng-container matColumnDef="name" sticky>
                            <th mat-header-cell mat-sort-header *matHeaderCellDef
                                class="text-left sub-header text-sm lg:text-md min-w-175"
                                [ngClass]="{'width-305 max-w-175 lg:min-w-305' : expandView, 'width-23' : !expandView}"
                                [ngClass]="investmentType?.displayShadowOnTable ? 'shadow-background' : 'normal-background'">
                                Deal Name </th>
                            <td mat-cell *matCellDef="let deal; let k = dataIndex"
                                class="text-left deal-row text-sm lg:text-md"
                                [ngClass]="{'deal-name': !deal.showHoldings && !deal.disableDrillThrough, 'width-23': !expandView, 'width-305': expandView, 'shadow-background':investmentType?.displayShadowOnTable, 'normal-background':!investmentType?.displayShadowOnTable}">
                                <div class="deal-name-div">
                                    <a *ngIf="!deal.showHoldings && !deal.disableDrillThrough && deal.holdings.length > 0" routerLinkActive="active" [routerLink]="redirectToHoldings(deal.holdings[0])" 
                                    [queryParams]="activatedRoute.snapshot.queryParams" data-cy="dealName">
                                        {{deal.name}}
                                    </a>
                                    <span *ngIf="deal.showHoldings || deal.disableDrillThrough || deal.holdings.length === 0" data-cy="dealName">
                                        {{deal.name}}
                                    </span>
                                    <img class="expand-collapse-icon-holding" [ngClass]="{'hidden': !deal.showHoldings}"
                                        (click)="deal.expanded = !deal.expanded"
                                        src="{{ deal.expanded ? iconSet['icon.series.collapse'] : iconSet['icon.expand']}}"
                                        attr.data-cy="dealCollapseIcon{{i}}{{k}}">
                                </div>
                            </td>
                        </ng-container>

                        <!-- Strategy Column -->
                        <ng-container matColumnDef="predominantStrategy">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef
                                class="text-left sub-header text-sm lg:text-md min-w-85"
                                [ngClass]="expandView ? 'width-160 max-w-85 lg:min-w-160' : 'width-12'">
                                Strategy </th>
                            <td mat-cell *matCellDef="let deal" class="text-left deal-row text-sm lg:text-md"
                                [ngClass]="expandView ? 'width-160' : 'width-12'" data-cy="dealStrategy"> {{deal.predominantStrategy}}
                            </td>
                        </ng-container>

                        <!-- Commitment Column -->
                        <ng-container matColumnDef="commitment">
                            <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                                class="text-right sub-header text-sm lg:text-md min-w-100"
                                [ngClass]="expandView ? 'width-160 lg:min-w-160' : 'width-10'"> Commitment </th>
                            <td mat-cell *matCellDef="let deal" class="text-right deal-row text-sm lg:text-md min-w-100"
                                [ngClass]="expandView ? 'width-160 lg:min-w-160' : 'width-10'"
                                data-cy="dealBalanceValue">
                                {{deal.commitment |
                                currencyFormat:currency:'Millions':true:true}} </td>
                        </ng-container>

                        <!-- Contributions Column -->
                        <ng-container matColumnDef="contributions">
                            <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                                class="text-right sub-header text-sm lg:text-md min-w-100"
                                [ngClass]="expandView ? 'width-245 lg:min-w-245' : 'width-10'">{{expandView ?
                                'Reported ': ''}}Contributions
                            </th>
                            <td mat-cell *matCellDef="let deal" class="text-right deal-row text-sm lg:text-md min-w-100"
                                [ngClass]="expandView ? 'width-245 lg:min-w-245' : 'width-10'"
                                data-cy="dealBalanceValue">
                                {{(expandView
                                ?
                                deal.reportedContributions : deal.contributions) |
                                currencyFormat:currency:'Millions':true:true}} </td>
                        </ng-container>

                        <!-- Distributions Column -->
                        <ng-container matColumnDef="distributions">
                            <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                                class="text-right sub-header text-sm lg:text-md min-w-100"
                                [ngClass]="expandView ? 'width-245 lg:min-w-245' : 'width-10'">{{expandView ?
                                'Reported ': ''}}Distributions
                            </th>
                            <td mat-cell *matCellDef="let deal" class="text-right deal-row text-sm lg:text-md min-w-100"
                                [ngClass]="expandView ? 'width-245 lg:min-w-245' : 'width-10'"
                                data-cy="dealBalanceValue">
                                {{(expandView
                                ?
                                deal.reportedDistributions : deal.distributions) |
                                currencyFormat:currency:'Millions':true:true}} </td>
                        </ng-container>

                        <!-- Net Asset Value Column -->
                        <ng-container matColumnDef="nav">
                            <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                                class="text-right sub-header text-sm lg:text-md min-w-70"
                                [ngClass]="expandView ? 'width-160 lg:min-w-160' : 'width-10'"> Net Asset Value </th>
                            <td mat-cell *matCellDef="let deal" class="text-right deal-row text-sm lg:text-md min-w-70"
                                [ngClass]="expandView ? 'width-160 post-valuation-date-section lg:min-w-160' : 'width-10'"
                                data-cy="dealBalanceValue"> {{deal.nav | currencyFormat:currency:'Millions':true:true}}
                            </td>
                        </ng-container>

                        <!-- Total Value Column -->
                        <ng-container matColumnDef="totalValue">
                            <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                                class="text-right sub-header text-sm lg:text-md min-w-70"
                                [ngClass]="expandView ? 'width-160 lg:min-w-160' : 'width-10'"> Total Value </th>
                            <td mat-cell *matCellDef="let deal" class="text-right deal-row text-sm lg:text-md min-w-70"
                                [ngClass]="expandView ? 'width-160 lg:min-w-160 post-valuation-date-section' : 'width-10'"
                                data-cy="dealBalanceValue"> {{deal.totalValue |
                                currencyFormat:currency:'Millions':true:true}} </td>
                        </ng-container>

                        <!-- TVPI Column -->
                        <ng-container matColumnDef="tvpi">
                            <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                                class="text-right sub-header text-sm lg:text-md min-w-70"
                                [ngClass]="expandView ? 'width-160 border-right' : 'width-7'"> TVPI </th>
                            <td mat-cell *matCellDef="let deal" class="text-right deal-row text-sm lg:text-md min-w-70"
                                [ngClass]="expandView ? 'width-160 border-right post-valuation-date-section' : 'width-7'"
                                data-cy="dealTvpiValue"> {{deal.tvpi | tvpiValueFormat}} </td>
                        </ng-container>

                        <!-- IRR Column -->
                        <ng-container matColumnDef="irr">
                            <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                                class="text-right sub-header text-sm lg:text-md min-w-70"
                                [ngClass]="expandView ? 'width-160' : 'width-7'"> IRR </th>
                            <td mat-cell *matCellDef="let deal" class="text-right deal-row text-sm lg:text-md min-w-70"
                                [ngClass]="expandView ? 'width-160' : 'width-7'" data-cy="dealIrrValue"> {{deal.irr |
                                irrValueFormat:true}} </td>
                        </ng-container>

                        <!-- Expanded View Columns -->
                        <!-- Investment Status Column -->
                        <ng-container matColumnDef="predominantRealizationType">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef
                                class="text-left width-160 sub-header text-sm lg:text-md min-w-130 max-w-130 lg:min-w-160">
                                Investment Status </th>
                            <td mat-cell *matCellDef="let deal" class="text-left width-160 deal-row text-sm lg:text-md"
                            data-cy="dealInvestmentStatus">
                                {{deal.predominantRealizationType}} </td>
                        </ng-container>

                        <!-- Vintage Year Column -->
                        <ng-container matColumnDef="vintageYear">
                            <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                                class="text-left width-120 sub-header text-sm lg:text-md min-w-70 max-w-70 lg:min-w-120">
                                Vintage Year
                            </th>
                            <td mat-cell *matCellDef="let deal" class="text-left width-120 deal-row text-sm lg:text-md"
                            data-cy="dealVintageYear">
                                {{deal.vintageYear}}
                            </td>
                        </ng-container>

                        <!-- Valuation Date Column -->
                        <ng-container matColumnDef="valuationDate">
                            <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                                class="text-left width-140 sub-header text-sm lg:text-md min-w-80 max-w-80 lg:min-w-140">
                                Valuation Date
                            </th>
                            <td mat-cell *matCellDef="let deal" class="text-left width-140 deal-row text-sm lg:text-md"
                            data-cy="dealValutationDate">
                                {{deal.valuationDate |
                                dateFormat: 'M/d/yy'}} </td>
                        </ng-container>

                        <!-- Remaining Commitment Column -->
                        <ng-container matColumnDef="remainingCommitment">
                            <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                                class="text-right width-245 sub-header text-sm lg:text-md min-w-100 lg:min-w-245">
                                Remaining
                                Commitment
                            </th>
                            <td mat-cell *matCellDef="let deal"
                                class="text-right width-245 deal-row text-sm lg:text-md min-w-100 lg:min-w-245"
                                data-cy="dealBalanceValueExpanded"> {{deal.remainingCommitment |
                                currencyFormat:currency:'Millions':true:true}}
                            </td>
                        </ng-container>

                        <!-- Reported Value Column -->
                        <ng-container matColumnDef="reportedValue">
                            <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                                class="text-right width-160 sub-header text-sm lg:text-md min-w-70 lg:min-w-160">
                                Reported Value
                            </th>
                            <td mat-cell *matCellDef="let deal"
                                class="text-right width-160 deal-row text-sm lg:text-md min-w-70 lg:min-w-160"
                                data-cy="dealBalanceValueExpanded"> {{deal.reportedValue |
                                currencyFormat:currency:'Millions':true:true}} </td>
                        </ng-container>

                        <!-- Subsequent Contributions Column -->
                        <ng-container matColumnDef="subsequentContributions">
                            <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                                class="text-right width-245 sub-header text-sm lg:text-md border-left min-w-100 lg:min-w-245">
                                Subsequent
                                Contributions </th>
                            <td mat-cell *matCellDef="let deal"
                                class="text-right width-245 deal-row border-left post-valuation-date-section text-sm lg:text-md  min-w-100 lg:min-w-245"
                                data-cy="dealBalanceValueExpanded"> {{deal.subsequentContributions |
                                currencyFormat:currency:'Millions':true:true}}
                            </td>
                        </ng-container>

                        <!-- Subsequent Distributions Column -->
                        <ng-container matColumnDef="subsequentDistributions">
                            <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                                class="text-right width-245 sub-header text-sm lg:text-md min-w-100 lg:min-w-245">
                                Subsequent
                                Distributions </th>
                            <td mat-cell *matCellDef="let deal"
                                class="text-right width-245 deal-row post-valuation-date-section text-sm lg:text-md min-w-100 lg:min-w-245"
                                data-cy="dealBalanceValueExpanded"> {{deal.subsequentDistributions |
                                currencyFormat:currency:'Millions':true:true}}
                            </td>
                        </ng-container>

                        <!-- fX Gain/(Loss) Column -->
                        <ng-container matColumnDef="fxGainLoss">
                            <th mat-header-cell mat-sort-header start="desc" *matHeaderCellDef
                                class="text-right width-160 sub-header text-sm lg:text-md min-w-100 lg:min-w-160"> FX
                                Gain/(Loss)
                            </th>
                            <td mat-cell *matCellDef="let deal"
                                class="text-right width-160 deal-row post-valuation-date-section text-sm lg:text-md min-w-100 lg:min-w-160"
                                data-cy="dealBalanceValueExpanded"> {{deal.fxGainLoss |
                                currencyFormat:currency:'Millions':true:true}}
                            </td>
                        </ng-container>

                        <!-- Header row first section -->
                        <ng-container matColumnDef="header-row-first-group" sticky>
                            <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="1"
                                class="series-holding-table-th"
                                [ngClass]="investmentType?.displayShadowOnTable ? 'shadow-background' : 'normal-background'">
                            </th>
                        </ng-container>

                        <!-- Header row second section -->
                        <ng-container matColumnDef="header-row-second-group">
                            <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="9"
                                class="series-holding-table-th">
                            </th>
                        </ng-container>

                        <!-- Header row third sction -->
                        <ng-container matColumnDef="header-row-third-group">
                            <th mat-header-cell *matHeaderCellDef [attr.colspan]="6"
                                class="series-holding-table-th post-valuation-date-section"> Post Valuation Date
                                Activity
                                Including Cash Roll Forward </th>
                        </ng-container>

                        <!-- Header row fourth section -->
                        <ng-container matColumnDef="header-row-fourth-group">
                            <th mat-header-cell *matHeaderCellDef [attr.colspan]="1" class="series-holding-table-th">
                            </th>
                        </ng-container>

                        <!-- Beginning Expanded Rows -->
                        <ng-container matColumnDef="expandedDetail">
                            <td *matCellDef="let deal; let j = dataIndex" [attr.colspan]="17" class="p-0">
                                <div [@detailExpand]="deal.expanded ? 'expanded' : 'collapsed'">
                                    <div *ngIf="deal.expanded">
                                        <table mat-table [dataSource]="deal.holdings" class="series-holding-table">
                                            <!-- Name Column -->
                                            <ng-container matColumnDef="name" sticky>
                                                <td mat-cell *matCellDef="let holding; let first = first"
                                                    [ngClass]="{'holding-name': !holding.disableDrillThrough, 'border-top-0': first, 'width-305 max-w-175 lg:min-w-305': expandView, 'width-23': !expandView, 'shadow-background':investmentType?.displayShadowOnTable, 'normal-background':!investmentType?.displayShadowOnTable}"
                                                    class="text-left holding-row text-sm lg:text-md min-w-175"
                                                    [attr.colspan]="1" attr.data-cy="holdingRowCell{{i}}{{j}}" >
                                                    <a *ngIf="!holding.disableDrillThrough" routerLinkActive="active" [routerLink]="redirectToHoldings(holding)" 
                                                        [queryParams]="activatedRoute.snapshot.queryParams"data-cy="holdingsRedirectLink">
                                                        {{holding.name}}
                                                    </a>
                                                    <span *ngIf="holding.disableDrillThrough" data-cy="holdingsRedirectLink">
                                                        {{holding.name}}
                                                    </span>
                                                </td>
                                            </ng-container>

                                            <!-- Strategy Column -->
                                            <ng-container matColumnDef="predominantStrategy">
                                                <td mat-cell *matCellDef="let holding; let first = first"
                                                    class="text-left holding-row text-sm lg:text-md min-w-85"
                                                    [ngClass]="{'border-top-0': first, 'width-160 max-w-85 lg:min-w-160': expandView, 'width-12': !expandView}"
                                                    [attr.colspan]="1" data-cy="holdingStrategy">{{holding.strategy}}</td>
                                            </ng-container>

                                            <!-- Commitment Column -->
                                            <ng-container matColumnDef="commitment">
                                                <td mat-cell *matCellDef="let holding; let first = first"
                                                    class="text-right holding-row text-sm lg:text-md min-w-100"
                                                    [ngClass]="{'border-top-0': first, 'width-160 lg:min-w-160': expandView, 'width-10': !expandView}"
                                                    data-cy="holdingBalanceValue">
                                                    {{holding.commitment |
                                                    currencyFormat:currency:'Millions':true:true}}
                                                </td>
                                            </ng-container>

                                            <!-- Contributions Column -->
                                            <ng-container matColumnDef="contributions">
                                                <td mat-cell *matCellDef="let holding; let first = first"
                                                    [ngClass]="{'border-top-0': first, 'width-245 lg:min-w-245': expandView, 'width-10': !expandView}"
                                                    class="text-right holding-row text-sm lg:text-md min-w-100"
                                                    data-cy="holdingBalanceValue">
                                                    {{(expandView ? holding.reportedContributions :
                                                    holding.contributions) |
                                                    currencyFormat:currency:'Millions':true:true}} </td>
                                            </ng-container>

                                            <!-- Distributions Column -->
                                            <ng-container matColumnDef="distributions">
                                                <td mat-cell *matCellDef="let holding; let first = first"
                                                    [ngClass]="{'border-top-0': first, 'width-245 lg:min-w-245': expandView, 'width-10': !expandView}"
                                                    class="text-right holding-row text-sm lg:text-md min-w-100"
                                                    data-cy="holdingBalanceValue">
                                                    {{(expandView ? holding.reportedDistributions :
                                                    holding.distributions) |
                                                    currencyFormat:currency:'Millions':true:true}} </td>
                                            </ng-container>

                                            <!-- Net Asset Value Column -->
                                            <ng-container matColumnDef="nav">
                                                <td mat-cell *matCellDef="let holding; let first = first"
                                                    [ngClass]="{'border-top-0': first, 'width-160 lg:min-w-160': expandView, 'width-10': !expandView}"
                                                    class="text-right holding-row text-sm lg:text-md min-w-70"
                                                    data-cy="holdingBalanceValue">
                                                    {{holding.nav | currencyFormat:currency:'Millions':true:true}} </td>
                                            </ng-container>

                                            <!-- Total Value Column -->
                                            <ng-container matColumnDef="totalValue">
                                                <td mat-cell *matCellDef="let holding; let first = first"
                                                    [ngClass]="{'border-top-0': first, 'width-160 lg:min-w-160': expandView, 'width-10': !expandView}"
                                                    class="text-right holding-row text-sm lg:text-md min-w-70"
                                                    data-cy="holdingBalanceValue">
                                                    {{holding.totalValue |
                                                    currencyFormat:currency:'Millions':true:true}} </td>
                                            </ng-container>

                                            <!-- TVPI Column -->
                                            <ng-container matColumnDef="tvpi">
                                                <td mat-cell *matCellDef="let holding; let first = first"
                                                    [ngClass]="{'border-top-0': first, 'width-160 border-right': expandView, 'width-7': !expandView}"
                                                    class="text-right holding-row text-sm lg:text-md min-w-70"
                                                    data-cy="holdingTvpiValue">
                                                    {{holding.tvpi |
                                                    tvpiValueFormat}} </td>
                                            </ng-container>

                                            <!-- IRR Column -->
                                            <ng-container matColumnDef="irr">
                                                <td mat-cell *matCellDef="let holding; let first = first"
                                                    [ngClass]="{'border-top-0': first, 'width-160': expandView, 'width-7': !expandView}"
                                                    class="text-right holding-row text-sm lg:text-md min-w-70"
                                                    data-cy="holdingIrrValue">
                                                    {{holding.irr |
                                                    irrValueFormat:true}} </td>
                                            </ng-container>

                                            <!-- Expanded View Columns -->
                                            <!-- Investment Status Column -->
                                            <ng-container matColumnDef="predominantRealizationType">
                                                <td mat-cell *matCellDef="let holding; let first = first"
                                                    [ngClass]="{'border-top-0': first}"
                                                    class="text-left width-160 holding-row text-sm lg:text-md min-w-130 lg:min-w-160"
                                                    [attr.colspan]="1" data-cy="holdingInvestmentStatus">{{holding.status}}</td>
                                            </ng-container>

                                            <!-- Vintage Year Column -->
                                            <ng-container matColumnDef="vintageYear">
                                                <td mat-cell *matCellDef="let holding; let first = first"
                                                    [ngClass]="{'border-top-0': first}"
                                                    class="text-left width-120 holding-row text-sm lg:text-md min-w-70 lg:min-w-120"
                                                    [attr.colspan]="1" data-cy="holdingVintageYear">
                                                    {{holding.vintageYear}} </td>
                                            </ng-container>

                                            <!-- Valuation Date Column -->
                                            <ng-container matColumnDef="valuationDate">
                                                <td mat-cell *matCellDef="let holding; let first = first"
                                                    [ngClass]="{'border-top-0': first}"
                                                    class="text-left width-140 holding-row text-sm lg:text-md min-w-80 lg:min-w-140"
                                                    [attr.colspan]="1" data-cy="holdingValuationDate">{{holding.valuationDate |
                                                    dateFormat: 'M/d/yy'}}</td>
                                            </ng-container>

                                            <!-- Remaining Commitment Column -->
                                            <ng-container matColumnDef="remainingCommitment">
                                                <td mat-cell *matCellDef="let holding; let first = first"
                                                    [ngClass]="{'border-top-0': first}"
                                                    class="text-right width-245 holding-row text-sm lg:text-md min-w-100 lg:min-w-245"
                                                    data-cy="holdingBalanceValueExpanded"> {{holding.remainingCommitment
                                                    |
                                                    currencyFormat:currency:'Millions':true:true}} </td>
                                            </ng-container>

                                            <!-- Reported Value Column -->
                                            <ng-container matColumnDef="reportedValue">
                                                <td mat-cell *matCellDef="let holding; let first = first"
                                                    [ngClass]="{'border-top-0': first}"
                                                    class="text-right width-160 holding-row text-sm lg:text-md min-w-70 lg:min-w-160"
                                                    data-cy="holdingBalanceValueExpanded"> {{holding.reportedValue |
                                                    currencyFormat:currency:'Millions':true:true}} </td>
                                            </ng-container>

                                            <!-- Subsequent Contributions Column -->
                                            <ng-container matColumnDef="subsequentContributions">
                                                <td mat-cell *matCellDef="let holding; let first = first"
                                                    [ngClass]="{'border-top-0': first}"
                                                    class="text-right width-245 holding-row text-sm lg:text-md border-left min-w-100 lg:min-w-245"
                                                    data-cy="holdingBalanceValueExpanded">
                                                    {{holding.subsequentContributions
                                                    | currencyFormat:currency:'Millions':true:true}} </td>
                                            </ng-container>

                                            <!-- Subsequent Distributions Column -->
                                            <ng-container matColumnDef="subsequentDistributions">
                                                <td mat-cell *matCellDef="let holding; let first = first"
                                                    [ngClass]="{'border-top-0': first}"
                                                    class="text-right width-245 holding-row text-sm lg:text-md min-w-100 lg:min-w-245"
                                                    data-cy="holdingBalanceValueExpanded">
                                                    {{holding.subsequentDistributions
                                                    | currencyFormat:currency:'Millions':true:true}} </td>
                                            </ng-container>

                                            <!-- Fx Gain/Loss Column -->
                                            <ng-container matColumnDef="fxGainLoss">
                                                <td mat-cell *matCellDef="let holding; let first = first"
                                                    [ngClass]="{'border-top-0': first}"
                                                    class="text-right width-160 holding-row text-sm lg:text-md min-w-100 lg:min-w-160"
                                                    data-cy="holdingBalanceValueExpanded">
                                                    {{holding.fxGainLoss
                                                    | currencyFormat:currency:'Millions':true:true}} </td>
                                            </ng-container>

                                            <tr mat-row *matRowDef="let holding; columns: columnsToDisplay;" attr.data-cy="holdingRow{{holding.masterId}}"></tr>
                                        </table>
                                    </div>
                                </div>
                            </td>
                        </ng-container>
                        <!-- Ending Expanded Rows -->

                        <tr mat-header-row
                            *matHeaderRowDef="['header-row-first-group','header-row-second-group','header-row-third-group','header-row-fourth-group']; sticky: expandView"
                            [ngClass]="{'hidden': !expandView}"></tr>
                        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                        <tr mat-row *matRowDef="let deal; columns: columnsToDisplay;"
                            tourAnchor="series.dashboard.investment.holding" attr.data-cy="dealRow{{deal.masterId}}"></tr>
                        <tr mat-row *matRowDef="let deal; columns: ['expandedDetail']"
                            [ngClass]="{'hidden': !deal.expanded}"></tr>
                    </table>
                </div>
            </div>
        </ng-container>
    </div>
    <ng-template #noResults>
        <div class="w-full series-table-error" data-cy="noResults">
            <div class="row">
                <div class="col-12">
                    <div class=" mt-3">
                        <div>
                            <p class="text-center error-message">No Results</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>