import { BaseEntityDetails } from "./entity-details.class";

export class HoldingDashboardDetails extends BaseEntityDetails {
    investmentManagerMasterId: number;
    investmentManagerName: string;
    currency: string;
    fundSize: number;
    strategy: string;
    vintageYear: string;
    hasSpotlightInvestment: boolean;
    portfolioMasterId: number;
    portfolioName: string;
    portfolioSeriesMasterId: number;
    portfolioSeriesName: string;
    dealMasterId: number;
    dealName: string;
    dealPredominantAssetClass: string;
    proxyType: string;
}