import { ChangeDetectorRef, Component, Input, SimpleChanges } from '@angular/core';
import { HoldingDashboardDetails } from 'src/app/models/classes/holding-dashboard-details.class';
import { KeyValuePair } from 'src/app/models/classes/key-value-pair.class';
import { ModalService } from 'src/app/services/modal-service.service';

@Component({
  selector: 'app-holding-dashboard-summary',
  templateUrl: './holding-dashboard-summary.component.html',
  styleUrls: ['./holding-dashboard-summary.component.scss']
})
export class HoldingDashboardSummaryComponent {
  @Input() iconSet: KeyValuePair<string, string>;
  @Input() cfmanagerLogo: string = '';
  @Input() holdingTitle: string;
  @Input() holdingSummary: HoldingDashboardDetails;
  @Input() holdingSummaryText: string;
  showSummary: boolean = false;
  noResultsSeriesDetails: boolean = false;
  portfolioSeriesDetailsNoResults: boolean = false;

  constructor(
    private modalService: ModalService,
    private ref: ChangeDetectorRef
  ) { }

  openModal() {
    this.modalService.showDefaultModalComponent(this.holdingTitle, this.holdingSummaryText)
  }

  setShowMoreVisibility(el: any) {
    return el.offsetHeight < el.scrollHeight ||
      el.offsetWidth < el.scrollWidth;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.holdingSummaryText) {
      this.showSummary = this.holdingSummaryText !== '' ? true : false;
    }
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }
}
