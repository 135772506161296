import { Injectable } from '@angular/core';
import { MathComputationService } from './math-computation.service';

@Injectable({
  providedIn: 'root',
})
export class TvpiIrrFormatService {
  noChangeText = 'No Change from Prior Quarter';
  priorQuarterText = 'Prior Quarter : ';
  nAText = 'N/A';
  nMText = 'N/M';
  iconSet: Record<string, string> = {};

  constructor(private mathService: MathComputationService) { }

  deriveIrrAndTvpiIconAndTitle(qoQChange: number, currentValue: number, priorValue: number, iconSet: Record<string, string>, summary: {}, isTvpiValue?: boolean) {
    this.iconSet = iconSet;
    priorValue = priorValue ?? 0.0
    let icon = this.deriveIrrAndTvpiIcon(qoQChange, currentValue, priorValue, isTvpiValue);
    let title = this.deriveIrrAndTvpiTitle(qoQChange, currentValue, priorValue, isTvpiValue);
    if (isTvpiValue) {
      summary['tvpiIcon'] = icon; summary['tvpiTitle'] = title;
    }
    else {
      summary['irrIcon'] = icon; summary['irrTitle'] = title;
    }
  }

  private deriveIrrAndTvpiIcon(qoQChange, currentValue: number, priorValue: number, isTvpiValue: boolean) {
    if (!isNaN(qoQChange) && qoQChange !== null) {
      const formattedQoQValue = Number(this.mathService.roundNumber(currentValue, 1, isTvpiValue ? 2 : 3)) - Number(this.mathService.roundNumber(priorValue, 1, isTvpiValue ? 2 : 3));
      return formattedQoQValue > 0
        ? this.iconSet['icon.increase']
        : formattedQoQValue < 0
          ? this.iconSet['icon.decrease']
          : this.iconSet['icon.noChange'];
    } else {
      return null;
    }
  }

  private deriveIrrAndTvpiTitle(qoQChange, currentValue: number, priorValue: number, isTvpiValue: boolean) {
    if (!isNaN(qoQChange)) {
      let title = '';
      const formattedQoQValue = Number(this.mathService.roundNumber(currentValue, 1, isTvpiValue ? 2 : 3)) - Number(this.mathService.roundNumber(priorValue, 1, isTvpiValue ? 2 : 3));
      if (formattedQoQValue > 0 || formattedQoQValue < 0) {
        if (isTvpiValue) {
          title =
            this.priorQuarterText + this.getTvpiValue(priorValue);
        } else {
          title =
            this.priorQuarterText + this.getIrrValue(priorValue);
        }
      } else {
        title = this.noChangeText;
      }
      return title;
    }
    else {
      return this.nAText;
    }
  }

  getIrrValue(value, excel?: boolean, isDealOrHoldingValue?: boolean) {
    if (value !== undefined && value !== null) {
      if (value === 0) {
        return '-';
      }
      else {
        if (isDealOrHoldingValue && (value > 10 || value < -0.85)) { return this.nMText; }
        return (value * 100).toFixed(1) === '-0.0' ? '(0.0%)' : ((value < 0) ? '(' + Math.abs(Number(this.mathService.roundNumber(value * 100, 1, 1))) + '%)' : this.mathService.roundNumber(value * 100, 1, 1) + '%');
      }
    }
    else {
      return excel ? value : this.nMText;
    }
  }

  getTvpiValue(value, negativeZeroValueWithParenthesis?: boolean) {
    if (value !== undefined && value !== null) {
      if (value === 0) {
        return '-';
      }
      else {
        if (negativeZeroValueWithParenthesis) {
          return value.toFixed(2) === '-0.00' ? '(0.00x)' : ((value < 0) ? '(' + Math.abs(Number(this.mathService.roundNumber(value, 1, 2))) + 'x)' : this.mathService.roundNumber(value, 1, 2) + 'x');
        }
        else {
          return value.toFixed(2) === '-0.00' ? '0.00x' : ((value < 0) ? '(' + Math.abs(Number(this.mathService.roundNumber(value, 1, 2))) + 'x)' : this.mathService.roundNumber(value, 1, 2) + 'x');
        }
      }
    }
    else {
      return this.nMText;
    }
  }
}
