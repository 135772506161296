import { Component, Input, ElementRef, ViewChild, HostListener, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { ModalService } from 'src/app/services/modal-service.service';

@Component({
  selector: 'app-asset-summary',
  templateUrl: './asset-summary.component.html',
  styleUrls: ['./asset-summary.component.scss']
})
export class AssetSummaryComponent implements AfterViewChecked {
  @Input() assetType: string;
  @Input() assetData: Record<string, string>;
  @Input() title: string;
  @Input() summary: string;
  @Input() logo: string;
  @Input() slidingImgs = new Array();
  @Input() showingOnHoldingDashboard: boolean = false;
  showGallery: boolean;
  showMoreButton: boolean;
  showSummary: boolean;
  showLogo: boolean;
  @ViewChild('summaryElement') summaryEl: ElementRef;
  
  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    this.getShowMore()
  }

  constructor(
    private modalService: ModalService,
    private changeDetector : ChangeDetectorRef
  ) {
  }
  ngAfterViewChecked(): void {
    this.getShowMore()
  }

  getShowMore() {
      if (this.summaryEl) {
        this.showMoreButton = this.summaryEl.nativeElement.offsetHeight < this.summaryEl.nativeElement.scrollHeight ||
        this.summaryEl.nativeElement.offsetWidth < this.summaryEl.nativeElement.scrollWidth;      
        this.changeDetector.detectChanges();
      }
  }

  ngOnChanges(): void {
    this.showSummary = this.summary && this.summary !== '';
    this.showLogo = this.logo && this.logo !== '';
    this.showGallery = this.slidingImgs && this.slidingImgs.length !== 0;
    this.getShowMore()
  }

  openModal() {
    this.modalService.showDefaultModalComponent(this.title, this.summary)
  }
}
