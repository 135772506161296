<div tourAnchor="investor.dashboard.summary">
    <div class="grid grid-cols-2 md:grid-cols-4 widget-row md:max-h[135px] ml-[1px] gap-x-[16px] gap-y-[16px] md:pr-0"
        *ngIf="investorSummary">
        <div>
            <div class="card">
                <div class="card-body">
                    <div class="widget-img">
                        <img src="{{iconSet['icon.commitment']}}" alt="Investor Commitment" class="mt-3">
                    </div>
                    <h2 class="widget-value" data-cy="summaryValue">{{investorSummary.commitment | currencyFormat:investorSummary?.currency}}</h2>
                    <p class="widget-text">Investor Commitment</p>
                </div>
            </div>
        </div>
        <div>
            <div class="card">
                <div class="card-body">
                    <div class="widget-img">
                        <img src="{{iconSet['icon.investor.contributions']}}" alt="Investor Contributions">
                    </div>
                    <h2 class="widget-value" data-cy="summaryValue">{{investorSummary.contributions | currencyFormat:investorSummary?.currency}}
                    </h2>
                    <p class="widget-text">Investor Contributions
                    </p>
                </div>
            </div>
        </div>
        <div>
            <div class="card">
                <div class="card-body">
                    <div class="widget-img">
                        <img src="{{iconSet['icon.investor.distributions']}}" alt="Investor Distributions">
                    </div>
                    <h2 class="widget-value" data-cy="summaryValue">{{investorSummary.distributions | currencyFormat:investorSummary?.currency}}
                    </h2>
                    <p class="widget-text">Investor Distributions
                    </p>
                </div>
            </div>
        </div>
        <div>
            <div class="card">
                <div class="card-body">
                    <div class="widget-img">
                        <img src="{{iconSet['icon.investor.nav']}}" alt="Net Asset Value" class="mt-1">
                    </div>
                    <h2 class="widget-value" data-cy="summaryValue">{{investorSummary.nav | currencyFormat:investorSummary?.currency }}</h2>
                    <p class="widget-text">Investor Net Asset Value
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>