import { Component, ChangeDetectorRef, OnInit, ViewEncapsulation, NgZone, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Okta } from '../shared/okta.service';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { ContentfulService } from '../services/contentful.service';
import { EnvironmentVariablesService } from '../services/environment-variables.service';
import { LoggingService } from '../services/logging.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../services/user.service';
import { ContentfulConfigService } from '../services/contentful-config.service';
import { OktaAuth, Tokens } from '@okta/okta-auth-js';
import OktaSignIn from '@okta/okta-signin-widget';
import { LoadingService } from '../services/loading.service';
import {RaygunV2} from 'raygun4js';
declare const rg4js: RaygunV2

const DEFAULT_ORIGINAL_URI = window.location.origin;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],

  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  title = 'app';
  oktaSignIn: OktaSignIn;
  oktaAuth: OktaAuth;
  // Contentful Declarations
  loginPageContents: any[];
  contentLoading = true;
  error: any;
  pageContent: any;
  pageHeading: any;
  siteLogo: any = 'https://images.ctfassets.net/hhmmh7cybzrp/6HRf7sYiEfZmUb9TuZW86D/04f1d998510aacd52023292b56fddf32/GCM-Logo-white.png';
  clientScopeLogo: any;
  gcmLogo: string = "";
  copyrightText: any;
  privacyPolicyLinkText: any;
  privacyPolicyModelOnButtonText: any;
  privacyPolicyModelContents: any;
  backgroundImageUrl: any;
  returnUrl: string;

  constructor(
    private okta: Okta,
    private changeDetectorRef: ChangeDetectorRef, public router: Router,
    private contentfulService: ContentfulService,
    private evService: EnvironmentVariablesService,
    private loggingService: LoggingService,
    private modalService: NgbModal,
    private userService: UserService,
    private contentfulConfig: ContentfulConfigService,
    private loadingService: LoadingService,
    private activeRoute: ActivatedRoute
  ) {
    this.oktaSignIn = okta.getWidget();
    this.oktaAuth = okta.getOktaAuth();
  }

  async ngOnInit(): Promise<void> {
    const originalUri = this.oktaAuth.getOriginalUri();
    if (!originalUri || originalUri === DEFAULT_ORIGINAL_URI) {
      this.oktaAuth.setOriginalUri('/dashboard');
    }

    this.getContentFromContentful();

    if (this.userService.isAuthenticated()) {
      this.returnUrl = this.activeRoute.snapshot.queryParams?.returnUrl
      this.returnUrl ? this.router.navigateByUrl(this.returnUrl) : this.router.navigate(['/dashboard']);
    } else {
      this.oktaSignIn.authClient.token.getWithoutPrompt().then((response) => {
        this.logUserIn(response.tokens)
      }).catch(err => {
        if (err.errorCode === "login_required") {
          this.clearSessionAndShowLogin();
        }
        else {
          console.error("auth error", err);
          this.loadingService.setLoading(false);
          this.router.navigate(['/auth-error'])
        }
      });
    }
    this.getContentFromContentful();
  }

  logUserIn(tokens) {
    this.loadingService.setLoading(true);
    this.changeDetectorRef.detectChanges();
    this.okta.getOktaAuth().tokenManager.setTokens(tokens);
    this.okta.getOktaAuth().authStateManager.updateAuthState().then(() => {
      this.userService.initUserInfo();
      this.fetchContentfulKeyAndRedirect(tokens);
    })
  }

  clearSessionAndShowLogin(): void {
    this.okta.getOktaAuth().tokenManager.clear();
    (rg4js as any)('setUser', {
      isAnonymous: true,
    });
    const self = this;
    this.oktaSignIn.on('afterError', (_context, error) => {
      self.loggingService.log((error.message ? error.message + ' - ' : '') + error.xhr.responseJSON.errorSummary);
    });
    this.oktaSignIn.showSignInToGetTokens({ el: '#okta-login-container' }
    ).then((tokens: Tokens) => {
      this.loggingService.log(tokens.idToken.claims.email + ' - Login Successfully');
      this.logUserIn(tokens);
    }).catch(function (error) {
      self.loggingService.log(error);
    });
  }

  fetchContentfulKeyAndRedirect(tokens: Tokens) {
    this.contentfulService.initializeContentfulKey();
    if (!this.userService.isExternalUser()) {
      this.oktaSignIn.authClient.handleLoginRedirect(tokens);
    }
    else {
      this.contentfulService.getTermsValue().subscribe(tncDetails => {
        if (tncDetails) {
          this.error = tncDetails.error;
          const tncVersion = tncDetails.data.landingPage.sys.publishedVersion;
          if (tncVersion > this.userService.getTermsAndConditionsAcceptedVersion()) {
            this.router.navigate(['/terms']);
          } else {
            this.oktaSignIn.authClient.handleLoginRedirect(tokens);
          }
        }
      });
    }
  }

  open(content) {
    this.modalService.open(content);
  }

  getContentFromContentful(): void {
    this.contentfulService.getLoginPage(this.evService.contentful.previewFlag).subscribe((result: any) => {
      this.loginPageContents = result?.data?.landingPage;
      this.error = result.error;
      // Page Branding content
      this.backgroundImageUrl = result?.data?.landingPage?.backgroundImage.url;
      this.pageHeading = result?.data?.landingPage?.sectionsCollection.items[0].heading;
      this.pageContent = documentToHtmlString(result?.data?.landingPage?.sectionsCollection.items[0].content.json).replace(this.contentfulConfig.summaryRegExp, this.contentfulConfig.summaryValueToReplace);
      this.siteLogo = result?.data?.landingPage?.sectionsCollection.items[0].logo.url;
      this.clientScopeLogo = result?.data?.landingPage?.clientScopeLogo.url;
      this.gcmLogo = result?.data?.landingPage?.gcmLogo.url;
      this.copyrightText = result?.data?.landingPage?.sectionsCollection.items[0].copyright;
      // privacy policy content
      this.privacyPolicyLinkText = this.contentfulService.getResourceValue(result?.data?.landingPage?.sectionsCollection.items[1].resourcesCollection.items, 'key', 'login.privacyterms.title');
      this.privacyPolicyModelContents = this.contentfulService.getResourceValue(result?.data?.landingPage?.sectionsCollection.items[1].resourcesCollection.items, 'key', 'login.privacyterms.text');
      this.privacyPolicyModelOnButtonText = this.contentfulService.getResourceValue(result?.data?.landingPage?.sectionsCollection.items[1].resourcesCollection.items, 'key', 'login.privacyterms.label.ok');
    });
  }
}