import { UserService } from './../../services/user.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentfulService } from '../../services/contentful.service';
import { InvestorRepository } from 'src/app/services/repositories/investor.repository';
import { Observable, Subscription, map, startWith } from 'rxjs';
import { BreadcrumbDataService } from 'src/app/services/breadcrumb-data.service';
import { FormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MetadataService } from 'src/app/services/metadata.service';
import { PortfoliosMeta } from 'src/app/models/classes/portfolios-meta.class';
import { InvestorMeta } from 'src/app/models/classes/investor-meta.class';

@Component({
  selector: 'app-investor-dropdown',
  templateUrl: './investor-dropdown.component.html',
  styleUrls: ['./investor-dropdown.component.scss']
})
export class InvestorDropdownComponent implements OnInit {
  @Input() updateWidth: boolean = false;
  iconSet: {};
  selectedInvestorId: number;
  investorsDropdownData: InvestorMeta[] = [];
  $investors: Subscription;
  investorName: string = '';
  showInvestorsDropList: boolean = false;
  queryParamsInvestorId: string = '';
  searchInput = new FormControl<string>('');
  filteredOptions: Observable<PortfoliosMeta[]>;
  portfolioData: PortfoliosMeta[] = [];
  filteredPortfolioData: PortfoliosMeta[] = [];
  @ViewChild('auto') autocomplete: MatAutocomplete;
  selectAllOptionInFilter: any = { name: '' }
  filteredInvestorDropdownData: InvestorMeta[] = [];
  noMatchFilterError: boolean = false;
  externalUser: boolean = true;

  constructor(
    public router: Router,
    private contentfulService: ContentfulService,
    private investorRepository: InvestorRepository,
    private userService: UserService,
    private activeRoute: ActivatedRoute,
    private breadcrumbDataService: BreadcrumbDataService,
    private metadataService: MetadataService
  ) {
  }

  ngOnInit(): void {
    this.externalUser = this.userService.isExternalUser();
    let queryParams = this.activeRoute.snapshot.queryParams;
    let investorIdFromParams = this.activeRoute?.snapshot?.firstChild?.params['investorId'] || '';
    this.queryParamsInvestorId = (queryParams['investorid'] === undefined) ? investorIdFromParams : queryParams['investorid'];
    this.getContentfulData();
    this.$investors = this.investorRepository.getAllEntities().subscribe((res: InvestorMeta[]) => {
      if (!res || res.length == 0) return;
      this.investorsDropdownData = res;
      this.filteredInvestorDropdownData = res;
      this.showInvestorsDropList = this.filteredInvestorDropdownData.length > 1;
      const cookieSelectedInvestorId = this.userService.getCookieValue('selectedInvestorId');
      this.selectedInvestorId = this.queryParamsInvestorId === '' && cookieSelectedInvestorId === ''
        ? this.filteredInvestorDropdownData[0].id
        : (this.queryParamsInvestorId === '' ? cookieSelectedInvestorId : this.queryParamsInvestorId)
      if (cookieSelectedInvestorId === '') {
        this.userService.setCookieValue('selectedInvestorId', this.selectedInvestorId);
      }
      if (this.userService.userIsOnInvestorDashboard()) {
        this.investorRepository.setActiveEntity(this.selectedInvestorId);
      }
      this.getInvestorName(this.selectedInvestorId);
      if (!this.externalUser) {
        this.metadataService.fetchPortfolioMetadata().then((metadata: PortfoliosMeta[]) => {
          this.portfolioData = metadata;
          this.filteredOptions = this.searchInput.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '')),
          );
        })
      }
    })
  }

  private _filter(value: any): PortfoliosMeta[] {
    const filterValue = value.name ? value.name.toLowerCase() : value.toLowerCase();
    this.filteredPortfolioData = this.portfolioData.filter(p => p.name.toLowerCase().includes(filterValue));
    if (this.filteredPortfolioData.length > 0) {
      this.selectAllOptionInFilter.name = this.filteredPortfolioData.length === 1 ? '' : filterValue;
      this.noMatchFilterError = false;
    }
    else {
      this.selectAllOptionInFilter.name = '';
      this.noMatchFilterError = true;
    }
    return this.filteredPortfolioData;
  }

  filterSelectedPortfolio() {
    const filteredPortfolioInvestorIds = this.filteredPortfolioData.map(pd => pd.relatedInvestors)[0];
    this.filteredInvestorDropdownData = [];
    this.investorsDropdownData.forEach(inv => {
      if (filteredPortfolioInvestorIds.includes(inv.id)) {
        this.filteredInvestorDropdownData.push(inv);
      }
    });
    this.selectedInvestorId = this.filteredInvestorDropdownData[0].id;
    this.selectActiveInvestor(this.selectedInvestorId);
  }

  displayFn(option: any): string {
    return option && option.name ? option.name : '';
  }

  resetFilter() {
    if (this.searchInput.value && this.searchInput.value !== '') {
      this.searchInput.reset('');
      this.autocomplete.options.forEach((item) => {
        item.deselect()
      });
      this.filteredInvestorDropdownData = Object.assign([], this.investorsDropdownData);
    }
  }

  getContentfulData(): void {
    this.contentfulService.getIconSetValue().subscribe(data => {
      if (data) this.iconSet = data;
    });
  }

  selectActiveInvestor(id: any): void {
    this.userService.setCookieValue('selectedInvestorId', id);
    this.getInvestorName(id);
    if (this.userService.userIsOnInvestorDashboard()) {
      this.queryParamsInvestorId = id;
      const reportingPeriod = this.breadcrumbDataService.getReportingPeriodForSelectedInvestor(id);
      this.router.navigate(
        [], {
        relativeTo: this.activeRoute,
        queryParams: { reportingdate: reportingPeriod, investorid: id },
        queryParamsHandling: 'merge'
      });
      setTimeout(() => {
        this.investorRepository.setActiveEntity(id);
      }, 300);
    } else {
      this.breadcrumbDataService.redirectToDashboard(id)
    }
  }

  getInvestorName(id) {
    if (id) {
      let selectedInvestor = this.investorsDropdownData.filter(investor => investor.id === Number(id))[0];
      if (selectedInvestor?.name && selectedInvestor?.name?.length < 100) {
        this.investorName = selectedInvestor?.name
      } else {
        this.investorName = selectedInvestor?.name?.substring(0, 100) + "..."
      }
    }
  }
}
