<div class="row portfolio-series-title-row title-row grid grid-cols-1 mr-0">
    <div class="title">
        <div class="d-flex">
            <h2 class="title__summary text-xl md:text-4xl">Investor Summary by Portfolio</h2>
            <app-info-icon tooltipPosition="right" tooltipInfo="Net of GCM Fees and Expenses"
                tooltipClass="mb-[10px] top-[-2px] md:top-[2px] lg:top-[0px]">
            </app-info-icon>
        </div>
    </div>
    <div class="since-inception-text block">Since Inception</div>
</div>
<ng-container *ngFor="let portfolio of investorPortfolios | sortBy:'asc' : 'ticker', let i = index">
    <div class="row portfolio-series-details-row mt-4 mr-0">
        <div class="card">
            <div class="card-body">
                <div [tourAnchor]="i === 0 ? 'investor.dashboard.portfolio' : ''">
                    <div class="title text-lg md:text-2xl flex items-center" attr.data-cy="portfolioName{{i}}">
                        <div>
                            {{portfolio.name}}
                        </div>
                        <div class="breadcrumbAsOfDate m-0 mt-[3px] md:mt-[5px] p-0 md:pl-[5px] flex" data-cy="asOfDate">as of
                            {{selectedAsOfDate | dateFormat : 'MM/dd/yyyy'}}
                            <div *ngIf="portfolio.publishValuationType === 'Audit'" data-cy="auditPublished" class="final">-- Final</div>
                        </div>
                    </div>
                    <div *ngIf="portfolio?.portfolioBalanceSummary?.length > 0" class="row investment-details-row grid gap-4 grid-cols-2 md:grid-cols-6 md:gap-0"
                        attr.data-cy="portfolioSummary{{i}}">
                        <div class="value-title pr-[42px] md:pr-[15px]">
                            <div class="md:float-left md:relative">
                                <h3 class="summary-value" data-cy="balanceValue">
                                    {{portfolio?.commitment | currencyFormat:portfolio?.currency}}
                                </h3>
                                <span class="summary-title">Commitment</span>
                            </div>
                        </div>
                        <div class="value-title pr-[42px] md:pr-[15px]">
                            <div class="md:float-left md:relative">
                                <h3 class="summary-value" data-cy="balanceValue">
                                    {{portfolio?.contributions | currencyFormat:portfolio?.currency}}</h3>
                                <span class="summary-title">Contributions</span>
                            </div>
                        </div>
                        <div class="value-title pr-[42px] md:pr-[15px]">
                            <div class="md:float-left md:relative">
                                <h3 class="summary-value" data-cy="balanceValue">
                                    {{portfolio?.distributions | currencyFormat:portfolio?.currency}}</h3>
                                <span class="summary-title">Distributions</span>
                            </div>
                        </div>
                        <div class="value-title pr-[42px] md:pr-[15px]">
                            <div class="md:float-left md:relative">
                                <h3 class="summary-value" data-cy="balanceValue">
                                    {{portfolio?.nav | currencyFormat:portfolio?.currency}}
                                </h3>
                                <span class="summary-title">Net Asset Value</span>
                            </div>
                        </div>
                        <div class="value-title">
                            <div class="md:float-left md:relative">
                                <h3 class="summary-value">
                                    <span data-cy="tvpiValue"
                                        [ngClass]="{'no-icon': !portfolio?.tvpiIcon}">{{portfolio?.netTvpi}}</span>
                                    <img *ngIf="portfolio?.tvpiIcon" src="{{portfolio?.tvpiIcon}}" alt="sample img"
                                        [matTooltip]="portfolio?.tvpiTitle" matTooltipClass="blue-tooltip"
                                        matTooltipPosition="right" class="widget-img" attr.data-cy="tvpiIcon{{i}}">
                                </h3>
                                <span class="summary-title with-icon">Net TVPI</span>
                            </div>
                        </div>
                        <div class="value-title">
                            <div class="md:float-left md:relative">
                                <h3 class="summary-value">
                                    <span data-cy="irrValue"
                                        [ngClass]="{'no-icon': !portfolio?.irrIcon}">{{portfolio?.netIrr }}</span>
                                    <img *ngIf="portfolio?.irrIcon" src="{{portfolio?.irrIcon}}" alt="sample img"
                                        [matTooltip]="portfolio?.irrTitle" matTooltipClass="blue-tooltip"
                                        matTooltipPosition="right" class="widget-img" attr.data-cy="irrIcon{{i}}">
                                </h3>
                                <span class="summary-title with-icon">Net IRR</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ccd-chart" *ngIf="portfolio?.portfolioBalanceSummary?.length > 0">
                    <app-cumulative-chart [cumulativeData]="portfolio" [selectedAsOfDate]="selectedAsOfDate"></app-cumulative-chart>
                </div>
                <ng-container *ngFor="let series of portfolio.portfolioSeries, let j = index">
                    <div [tourAnchor]="j === 0 ? 'investor.dashboard.series' : ''">
                        <div class="portfolio-series" [ngClass]="{'br-top-rounded': series.isExpanded}"
                            [tourAnchor]="j === 0 ? 'investor.dashboard.series.drillThrough' : ''">
                            <div class="series-row row grid gap-4 grid-cols-2 md:grid-cols-6 md:gap-0"
                                attr.data-cy="seriesSummary{{i}}{{j}}">
                                <div class="col-span-4 series-name">
                                    <span *ngIf="!series.hasCommitments" class="series-title series-title-disabled"
                                        matTooltipClass="blue-tooltip-xl" matTooltipPosition="above"
                                        [matTooltip]="noInvDataMsg" attr.data-cy="seriesName{{series.masterId}}">{{series.name}}</span>
                                    <a *ngIf="series.hasCommitments" class="series-title" routerLinkActive="active" [routerLink]="redirectToSeriesPage(series)"
                                        [queryParams]="getQueryParams(series)" attr.data-cy="seriesName{{series.masterId}}">{{series.name}}</a>
                                    <ng-container *ngFor="let assetImage of series.assetClassImage, let k = index">
                                        <img class="investment-type" [src]="assetImage[1]" alt="asset image"
                                            attr.data-cy="assetImage{{i}}{{j}}{{k}}" [matTooltip]="assetImage[0]"
                                            matTooltipClass="blue-tooltip" matTooltipPosition="right">
                                    </ng-container>
                                </div>
                                <div class="value-title pl-0">
                                    <div class="md:float-left md:relative pr-[15px]">
                                        <span class="series-title">
                                            <span data-cy="tvpiValue"
                                                [ngClass]="{'no-icon': !series?.tvpiIcon}">{{series?.netTvpi}}</span>
                                            <img *ngIf="series?.tvpiIcon" attr.data-cy="tvpiIcon{{i}}{{j}}"
                                                src="{{series.tvpiIcon}}" alt="sample img" class="widget-img"
                                                [matTooltip]="series.tvpiTitle" matTooltipClass="blue-tooltip"
                                                matTooltipPosition="right"></span>
                                        <span class="series-labels">Net TVPI</span>
                                    </div>
                                </div>
                                <div class="value-title pl-0">
                                    <div class="md:float-left md:relative pr-[15px]">
                                        <span class="series-title">
                                            <span data-cy="irrValue"
                                                [ngClass]="{'no-icon': !series?.irrIcon}">{{series?.netIrr}}</span>
                                            <img *ngIf="series?.irrIcon" attr.data-cy="irrIcon{{i}}{{j}}"
                                                src="{{series.irrIcon}}" alt="sample img" class="widget-img"
                                                [matTooltip]="series.irrTitle" matTooltipClass="blue-tooltip"
                                                matTooltipPosition="right"></span>
                                        <span class="series-labels">Net IRR</span>
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="!series.isExpanded && !series.hideButton">
                                <img class="down-icon" (click)="expandSeriesPortfolio(series)"
                                    src="{{iconSet['icon.expand']}}" attr.data-cy="expandIcon{{i}}{{j}}">
                            </ng-container>
                        </div>
                        <ng-container *ngIf="series.isExpanded">
                            <img *ngIf="!series.hideButton" class="up-icon mt-[131px] md:mt-[80px]" (click)="collapseSeriesPortfolio(series)"
                                src="{{iconSet['icon.collapse']}}" attr.data-cy="collapseIcon{{i}}{{j}}">
                            <div class="portfolio-expanded-container" attr.data-cy="expandedViews{{i}}{{j}}">
                                <div class="expanded-view grid gap-4 grid-cols-1 lg:grid-cols-3 lg:gap-0">
                                    <div class="widgets">
                                        <app-base-widget [error]="error" [card]="false"
                                            attr.data-cy="seriesCommitmentChart{{i}}{{j}}">
                                            <gcm-chart chartType="column" noMerge="true"
                                                [chartStyle]="cumulativeChartStyle"
                                                [chartOptions]="series.committedFundedChart" />
                                        </app-base-widget>
                                    </div>
                                    <div class="widgets">
                                        <app-base-widget [error]="error" [card]="false"
                                            attr.data-cy="seriesNavDisConChart{{i}}{{j}}">
                                            <gcm-chart noMerge="true" [chartStyle]="cumulativeChartStyle"
                                                [chartOptions]="series.NavDisConChart" />
                                        </app-base-widget>
                                    </div>
                                    <div class="widgets">
                                        <app-base-widget class="relative top-[28px] md:top-[0]" [error]="error"
                                            [card]="false" attr.data-cy="seriesNavDistributionChart{{i}}{{j}}">
                                            <gcm-chart noMerge="true" [chartStyle]="cumulativeChartStyle"
                                                [chartOptions]="series.navDistributionChart" />
                                        </app-base-widget>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>