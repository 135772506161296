<li class="mr-3 dropdown_li">
    <div class="text-right pl-10 md:pl-14 lg:pl-0 lg:flex" *ngIf="showInvestorsDropList && iconSet">
        <form class="filter-form" *ngIf="!externalUser">
            <div class="flex mr-2">
                <mat-label class="mr-1 mt-2">Filter:</mat-label>
                <mat-form-field class="mt-1 filter-form-field">
                    <input type="text" placeholder="Filter by portfolio" matInput [formControl]="searchInput"
                        [matAutocomplete]="auto" class="form-control">
                    <mat-icon *ngIf="searchInput.value !== ''" aria-label="close icon"
                        class="pb-2 pointer close-icon" fontIcon="close_small" color="red" title="Clear Filter"
                        (click)="resetFilter()"></mat-icon>
                    <mat-autocomplete #auto="matAutocomplete" [hideSingleSelectionIndicator]="true"
                        [displayWith]="displayFn" panelWidth="40%" (optionSelected)="filterSelectedPortfolio()">
                        <mat-option *ngIf="selectAllOptionInFilter.name !== ''" [value]="selectAllOptionInFilter">Any
                            investors with: {{selectAllOptionInFilter.name}}</mat-option>
                        <mat-option *ngIf="noMatchFilterError" [disabled]="true">No matching investors with a portfolio
                            name:
                            {{searchInput.value}}</mat-option>
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            Investors with: {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </form>
        <div class="investorIds">
            <img src="{{iconSet['icon.investor.dropdown']}}" alt="investor-dropdown"
                class="investorIds__icon mt-4 lg:mt-0" />
            <app-auto-adjusting-dropdown [selected]="investorName" [dataForBreadcrumbs]="filteredInvestorDropdownData"
                (goToSelected)="selectActiveInvestor($event)" (sendWidth)="getWidth($event)"
                [queryParams]="selectedInvestorId" [updateWidth]="updateWidth" [valueProperty]="'id'" 
                [nameProperty] = "'name'" [outerdivClass]="'investor'" [optionClass]="'font-weight-bold'"
                [optionClassProperty] = "'isVerified'" [selectClass]="'lg:whitespace-nowrap'"></app-auto-adjusting-dropdown>
        </div>
    </div>
</li>