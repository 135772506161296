<div class={{outerdivClass}}>
  <div class="breadcrumb-dropdown max-w-300 md:max-w-2xl lg:max-w-5xl" [style.width.px]="width">
    <select #selectList [(ngModel)]="queryParams" (change)='sendSelected($event.target)' [style.width.px]="width"
       class="pointer text-smd selectClass">
      <ng-template [ngIf]="!groupProperty || groupProperty == ''" [ngIfElse]="showDropdownWithOptgroup">
        <option *ngFor="let data of dataForBreadcrumbs" [ngClass]="data[optionClassProperty] ? optionClass : ''" 
        [value]="data[valueProperty]">
          {{data[nameProperty]}}
        </option>
      </ng-template>
      <ng-template #showDropdownWithOptgroup>
        <ng-container *ngFor="let data of dataForBreadcrumbs">
          <optgroup [attr.label]="data[nameProperty]">
            <ng-template [ngIf]="showOneOptGroup" [ngIfElse]="showDropdownWithMultipleOptgroup">
              <option *ngFor="let type of data[groupProperty]"
                [value]="type[valueProperty]">
                {{type[nameProperty]}}
              </option>
            </ng-template>
            <ng-template #showDropdownWithMultipleOptgroup>
              <ng-container *ngFor="let group of data[groupProperty]">
                <ng-template [ngIf]="group[groupProperty].length === 1" [ngIfElse]="showSecondOptgroup">
                  <option [value]="group[groupProperty][0][valueProperty]">
                    {{group[nameProperty]}}
                  </option>
                </ng-template>
                <ng-template #showSecondOptgroup>
                  <option [disabled]="true">
                    {{group[nameProperty]}}
                  </option>
                    <option *ngFor="let type of group[groupProperty]"
                      [value]="type[valueProperty]">
                      &nbsp;&nbsp;{{type[nameProperty]}}
                    </option>
                </ng-template>
              </ng-container>
            </ng-template>
          </optgroup>
        </ng-container>
      </ng-template>
    </select>
  </div>

  <div class="row h-0 lg:min-h-[20px]">
    <div class="col-12">
      <div class="d-flex">
        <div #hiddenSelected class="hiddenBreadcrumb text-smd"></div>
      </div>
    </div>
  </div>
</div>